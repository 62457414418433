
import { mixins } from 'vue-class-component';
import Common from '@main/mixins/Common';

export default class ProgressionBar extends mixins(Common) {
  public stepPercent() {
    return this.stepsKeys
      && Math.round(
        ((this.stepsKeysWithExtraSteps.indexOf(this.currentStep) + 1)
          / (this.stepsKeysWithExtraSteps.length)) * 100,
      );
  }

  public get uuid() {
    return this.$route.params.uuid;
  }
}
