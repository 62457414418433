import { reactive, readonly } from '@vue/reactivity';

export default abstract class AbstractStore<T extends Object> {
  protected state: T;

  protected initialized = false;

  protected loading = false;

  constructor() {
    this.state = reactive(this.data()) as T;
    this.setup();
  }

  protected abstract data(): T

  // eslint-disable-next-line
  protected setup(): void {}

  protected abstract initialize(): void;

  public getState(): T {
    this.initialize();

    return readonly(this.state) as T;
  }

  public get isInitialized(): boolean {
    return this.initialized;
  }

  public get isLoading(): boolean {
    return this.loading;
  }
}
