import AbstractStore from '@common/store/AbstractStore';
import AgencyDirector from '@common/store/AgencyDirector';
import Api from '@common/plugins/Api';
import SupplierStore from '@common/store/Supplier';
import Step from '@main/store/Step';

interface SubscriptionState {
  current: SubscriptionInterface|null;
  subscriptions: SubscriptionInterface[];
}

class Subscription extends AbstractStore<SubscriptionState> {
  protected data(): SubscriptionState {
    return {
      current: null,
      subscriptions: [],
    };
  }

  protected initialize(): void {
    if (!this.loading) {
      this.loading = true;
    }
  }

  public refresh(params: DataObjectInterface = {}): void {
    this.loading = true;
    this.state.subscriptions = [];
    Api.post('/api/subscription', { ...params })
      .then((response) => {
        this.state.subscriptions = response.data as unknown as SubscriptionInterface[];
      }).finally(() => {
        this.initialized = true;
        this.loading = false;
      });
  }

  public get current(): SubscriptionInterface|null {
    return this.getState().current;
  }

  public get subscriptions(): SubscriptionInterface[] {
    return this.getState().subscriptions;
  }

  public load(uuid: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      Api.get(`/api/subscription/detail/${uuid}`)
        .then((response) => {
          this.state.current = response.data as SubscriptionInterface;
          Step.maxStep = this.state.current.step;
          Step.currentStep = this.state.current.step;
          SupplierStore.selectProduct(this.state.current.product);
          resolve(true);
        })
        .catch((error) => reject(error.response.data.errors || {}));
    });
  }

  public select(subscription: SubscriptionInterface|null): void {
    this.state.current = subscription;
  }

  public create(
    client: string,
    product: string,
    category: string,
    contractNumber: string|null = null,
  ): Promise<UuidInterface> {
    return new Promise<UuidInterface>((resolve, reject) => {
      Api.put(`/api/subscription/create/${client}/${product}/${category}/${contractNumber}`)
        .then((response) => resolve(response.data || { uuid: '' }))
        .catch((error) => reject(error.response.data || {}));
    });
  }

  public duplicate(subscription: string, client: string): Promise<UuidInterface> {
    return new Promise<UuidInterface>((resolve, reject) => {
      Api.put(`/api/subscription/duplicate/${subscription}/${client}`)
        .then((response) => resolve(response.data || { uuid: '' }))
        .catch((error) => reject(error.response.data || {}));
    });
  }

  public cancel(subscription: string): Promise<UuidInterface> {
    return new Promise<UuidInterface>((resolve, reject) => {
      Api.put(`/api/subscription/cancel/${subscription}`)
        .then((response) => resolve(response.data || { uuid: '' }))
        .catch((error) => reject(error.response.data || {}));
    });
  }

  public saveData(uuid: string, step: string, data: object): Promise<object> {
    return new Promise<object>((resolve, reject) => {
      Api.put(`/api/subscription/validate/${uuid}/${step}`, data)
        .then((response) => resolve(response.data.data || {}))
        .catch((error) => reject(error.response.data || {}));
    });
  }

  private isAgencyDirector(member: SignatureLinkInterface): boolean {
    const { lastName, firstName } = member;

    return !!AgencyDirector.agencyDirectors.find((a) => a.lastName === lastName
      && a.firstName === firstName);
  }

  public getNotSignedMembers(subscription: SubscriptionInterface|null): SignatureLinkInterface[] {
    const signature = subscription && subscription.signature;

    return (signature ? signature.members : []).filter((member) => !this.isAgencyDirector(member)
      && !member.signedAt);
  }
}

export default new Subscription();
