import AbstractStore from './AbstractStore';
import Api from '../plugins/Api';

export interface UserInterface {
  firstName: string;
  lastName: string;
}

interface UserState {
  user: UserInterface|null;
}

class User extends AbstractStore<UserState> {
  protected data(): UserState {
    return {
      user: null,
    };
  }

  public get me(): UserInterface|null {
    return this.getState().user;
  }

  public get isAuthorized(): boolean {
    const auth = document.querySelector('meta[name="auth"]');

    return !!auth && auth.getAttribute('content') === '1';
  }

  public get token(): string {
    const userToken = document.querySelector('meta[name="user-token"]');

    return userToken ? (userToken.getAttribute('content') || '') : '';
  }

  public initialize() {
    if (this.loading) {
      return;
    }

    if (!this.isAuthorized) {
      this.initialized = true;
      return;
    }

    this.loading = true;
    Api.get('/me').then((response) => {
      this.state.user = response.data as unknown as UserInterface;
      Api.defaults.headers.common = { 'user-token': this.token };
    }).finally(() => {
      this.initialized = true;
    });
  }

  public logout(): void {
    this.state.user = null;
    window.location.href = '/logout';
  }
}

export default new User();
