import '@fortawesome/fontawesome-free/css/all.css';
import '@fontsource/source-sans-pro';
import sentry from '@common/sentry';
import './assets/css/index.scss';
import { createApp } from 'vue';
import Toaster from '@meforma/vue-toaster';
import App from './App.vue';
import Router from './router';

const app = createApp(App)
  .use(Toaster, {
    position: 'bottom-right',
  });

sentry(app, Router);
app.use(Router).mount('#app');
