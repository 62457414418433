
import { Options, Vue } from 'vue-class-component';
import FaqCni from '@common/components/faq/FaqCni.vue';

@Options({
  components: {
    FaqCni,
  },
})
export default class FaqAccordion extends Vue {}
