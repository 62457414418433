
import { Vue } from 'vue-class-component';
import Prop from '@common/decorators/Prop';

export default class Modal extends Vue {
  @Prop({ default: '64rem' }) readonly width!: string;

  private classes: string[] = [];

  public clickOut(event: PointerEvent): void {
    const target = event.target as HTMLElement;
    if (target && target.closest('.modal-container') !== this.$refs.container) {
      this.$emit('close');
    }
  }
}
