import AbstractStore from '@common/store/AbstractStore';
import Api from '@common/plugins/Api';

interface SupplierState {
  current: SupplierInterface|null;
  suppliers: SupplierInterface[];
}

class Supplier extends AbstractStore<SupplierState> {
  protected data(): SupplierState {
    return {
      current: null,
      suppliers: [],
    };
  }

  protected initialize(): void {
    if (!this.loading) {
      this.loading = true;
      this.refresh();
    }
  }

  private refresh(): void {
    Api.get('/api/subscription/supplier')
      .then((response) => {
        this.state.suppliers = response.data as unknown as SupplierInterface[];
      }).finally(() => {
        this.initialized = true;
      });
  }

  public get current(): SupplierInterface|null {
    return this.getState().current;
  }

  public get suppliers(): SupplierInterface[] {
    return this.getState().suppliers;
  }

  public select(item: UuidInterface|null): void {
    if (!item) {
      this.state.current = null;
      return;
    }
    this.state.suppliers.forEach((supplier) => {
      if (supplier.uuid === item.uuid) {
        this.state.current = supplier;
      }
    });
  }

  public selectProduct(item: UuidInterface): ProductInterface|null {
    let result: ProductInterface|null = null;
    if (!this.suppliers.length) {
      this.select(null);

      return result;
    }

    this.suppliers.forEach((supplier) => {
      supplier.products.forEach((product) => {
        if (product.uuid === item.uuid) {
          this.select(supplier);
          result = product;
        }
      });
    });

    return result;
  }
}

export default new Supplier();
