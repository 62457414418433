import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "span" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_base_tab = _resolveComponent("base-tab")!

  return (_ctx.$route.params.uuid)
    ? (_openBlock(), _createBlock(_component_base_tab, { key: 0 }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentStepKeys, (step, key) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: key }, [
              (_ctx.stepsName[step])
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: { name: `subscription.${step}`, params: { uuid: _ctx.$route.params.uuid }},
                    title: _ctx.stepsName[step],
                    class: _normalizeClass([{
          'current-step': `subscription.${step}` === _ctx.$route.name,
          'next-step': _ctx.currentStepKeys.indexOf(step) > _ctx.currentStepKeys.indexOf(_ctx.maxStep),
         }, "step"]),
                    tag: "button",
                    onClick: ($event: any) => (_ctx.changeStep(step))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.offsetStep + _ctx.currentStepKeys.indexOf(step)) + ". ", 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.stepsName[step]), 1)
                    ]),
                    _: 2
                  }, 1032, ["to", "title", "class", "onClick"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}