import { createDecorator, Vue, PropOptions } from 'vue-class-component';

export default function Prop(options: PropOptions) {
  return (target: Vue, key: string) => {
    createDecorator((componentOptions, k) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      // eslint-disable-next-line
      (componentOptions.props || ((componentOptions.props = {}) as any))[k] = options;
    })(target, key);
  };
}
