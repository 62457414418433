
import { Options, Vue } from 'vue-class-component';
import Modal from '@common/components/Modal.vue';
import FaqAccordion from '@common/components/faq/FaqAccordion.vue';

@Options({
  components: {
    Modal,
    FaqAccordion,
  },
})
export default class FaqModal extends Vue {}
