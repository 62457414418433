import { Vue } from 'vue-class-component';
import { BrowserTracing } from '@sentry/tracing';
import Api from '@common/plugins/Api';
import * as Sentry from '@sentry/vue';
import { Router } from 'vue-router';

export default function (app: Vue, router: Router) {
  Api.get('/sentry').then((response) => {
    Sentry.init({
      app,
      dsn: response.data.dsn,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
      ],
      tracesSampleRate: 0.2,
    });
  });
}
