<template>
  <div class="cs-loader-inner text-center">
    <label class="text-yellow-600"> ● </label>
    <label class="text-blue-700"> ● </label>
    <label> ● </label>
  </div>
</template>

<style lang="scss" scoped >
.cs-loader-inner label {
  display: inline-block;
  font-size: 1.875rem;
}

.cs-loader-inner label:nth-child(1) {
  animation: lol 3s 500ms infinite ease-in-out;
}
.cs-loader-inner label:nth-child(2) {
  animation: lol 3s 400ms infinite ease-in-out;
}
.cs-loader-inner label:nth-child(3) {
  animation: lol 3s 300ms infinite ease-in-out;
}

@keyframes lol {
  0% {
    opacity: 0;
    transform: translateX(-300px);
  }
  33% {
    opacity: 1;
    transform: translateX(0px);
  }
  66% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    opacity: 0;
    transform: translateX(300px);
  }
}
</style>
