
import { Options, mixins } from 'vue-class-component';
import Common from '@main/mixins/Common';
import BaseTab from '@main/components/BaseTab.vue';
import Step from '@main/store/Step';

@Options({
  components: {
    BaseTab,
  },
})
export default class TopMenu extends mixins(Common) {
  public changeStep(step: string) {
    Step.changeCurrentStep(step);
    if (step === 'dashboard') {
      Step.changeCurrentStep(null);
      this.$router.push({
        name: 'subscription.dashboard',
        params: { uuid: null },
      });
    }
  }
}
