import AbstractStore from '@common/store/AbstractStore';
import Api from '@common/plugins/Api';

interface AgencyDirectorState {
  agencyDirectors: AgencyDirectorInterface[];
}

class AgencyDirector extends AbstractStore<AgencyDirectorState> {
  protected data(): AgencyDirectorState {
    return {
      agencyDirectors: [],
    };
  }

  protected initialize(): void {
    if (!this.loading) {
      this.load();
      this.loading = true;
    }
  }

  public load(): void {
    Api.get('/api/agency-director')
      .then((response) => {
        this.state.agencyDirectors = response.data as unknown as AgencyDirectorInterface[];
      }).finally(() => {
        this.initialized = true;
      });
  }

  public get agencyDirectors(): AgencyDirectorInterface[] {
    return this.getState().agencyDirectors;
  }
}

export default new AgencyDirector();
