
import { Options, mixins } from 'vue-class-component';
import TopMenu from '@main/components/TopMenu.vue';
import ProgressionBar from '@main/components/ProgressionBar.vue';
import Information from '@main/components/Information.vue';
import Loading from '@main/components/Loading.vue';
import User from '@common/store/User';
import Subscription from '@main/mixins/Subscription';
import FaqModal from '@common/components/FaqModal.vue';

@Options({
  components: {
    TopMenu,
    ProgressionBar,
    Information,
    Loading,
    FaqModal,
  },
})
export default class App extends mixins(Subscription) {
  private showFaqModal = false;

  public get loading() {
    return User.isInitialized;
  }

  public get user() {
    return User.me;
  }

  public get subscriberName() {
    return this.subscription && this.subscription.subscriber && `${this.subscription.subscriber.information.civility} ${this.subscription.subscriber.information.lastName} ${this.subscription.subscriber.information.firstName}`;
  }
}
