import { Vue } from 'vue-class-component';
import SupplierStore from '@common/store/Supplier';
import Step from '@main/store/Step';

export default abstract class Common extends Vue {
  public loadOptions(uuid: string): Promise<boolean> {
    return Step.loadOptions(uuid);
  }

  public get options() {
    return Step.options;
  }

  public getSelectOptions(key: string) {
    return Step.getSelectOptions(key);
  }

  public getSelectValue(key: string, value: string|number) {
    return Step.getSelectValue(key, value);
  }

  public get stepsName() {
    return Step.stepsName;
  }

  public get supplier() {
    return SupplierStore.current;
  }

  public get currentStep() {
    return Step.getState().currentStep;
  }

  public get maxStep() {
    return Step.getState().maxStep;
  }

  public changeCurrentStep(step: string): void {
    Step.changeCurrentStep(step);
  }

  public get tnc(): string|null {
    return this.options ? this.options.tnc : null;
  }

  public get label(): string|null {
    return this.options ? this.options.label : null;
  }

  public get supplierName(): string|null {
    return this.options ? this.options.supplierName : null;
  }

  public get picture(): string|null {
    return this.options ? this.options.picture : null;
  }

  public get steps() {
    return this.options ? this.options.steps : {};
  }

  public get stepsKeys() {
    return Object.keys(this.steps || {});
  }

  public get extraSteps() {
    return ['attachments', 'electronic-signature'];
  }

  public get stepsKeysWithExtraSteps() {
    return [...Object.keys(this.steps || {}), ...this.extraSteps];
  }

  public get finalStep() {
    return this.stepsKeys.indexOf(this.currentStep || '') < 0;
  }

  public get currentStepKeys() {
    return this.finalStep ? this.extraSteps : this.stepsKeys;
  }

  public get offsetStep() {
    return 1 + (this.finalStep ? this.stepsKeys.length : 0);
  }

  public numberFormat(value: number|string|null, suffix = '', digit = 2): string {
    return Step.numberFormat(value, suffix, digit);
  }
}
